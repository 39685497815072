<template>
  <div class="v-playlists">
    <base-layout>
      <my-music-function-bar :config="functionConfig" />
      <my-music-list :config="listConfig" :contentData="contentData" />
    </base-layout>
  </div>
</template>
<script>
import {
  defineComponent, provide, ref, computed,
} from 'vue';

import BaseLayout from '@/components/MyMusic/BaseLayout.vue';
import MyMusicFunctionBar from '@/components/MyMusic/MyMusicFunctionBar.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import {
  contentData,
  functionConfig,
  mobileListConfig,
  pcListConfig,
  musicDetailColumns,
  musicDetailContentData,
  trackDivideColumns,
  trackDivideData,
  mobileListDetailColumns,
  pcListDetailColumns,
  listDetailContentData,
} from '@/config/myMusic/playlistsConfig';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MyMusicPlaylists',
  components: {
    BaseLayout,
    MyMusicList,
    MyMusicFunctionBar,
  },
  setup() {
    const siteStore = useSiteStore();
    const listContentConfig = ref({
      notice: '',
      expandDetail: 'list-detail', // list-detail, music-detail, ''
    });

    const musicDetail = ref({
      versionAndEdit: {
        columns: musicDetailColumns,
        data: musicDetailContentData,
      },
      track: {
        columns: trackDivideColumns,
        data: trackDivideData,
      },
      // mobile 點擊 row 展開的 key
      mobileExpandKey: 'detail',
    });

    const listConfig = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcListConfig;
      }

      return mobileListConfig;
    });

    const listDetailColumns = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcListDetailColumns;
      }

      return mobileListDetailColumns;
    });

    provide('listContentConfig', listContentConfig);
    provide('musicDetail', musicDetail);
    provide('detailConfig', () => ({
      listDetailColumns: listDetailColumns.value,
      listDetailContentData,
    }));

    return {
      functionConfig,
      listConfig,
      contentData,
    };
  },
});
</script>
