<template>
  <div class="c-edit-palylist-name">
    <div class="name">
      <input class="name__input" placeholder="難過的時候聽了就開心囉"/>
    </div>

    <div class="btn-wrap">
      <mi-button>Save</mi-button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'EditPlaylistName',
  components: {
    MiButton,
  },
});
</script>
<style lang="scss" scoped>
.name {
  margin: 36px 45px 0;
  @include padding(0 25px);
  @include flex();
  border: 1px solid #a0a0a0;
  border-radius: 25px;
  height: 50px;

  &__input {
    @include font-style($c-assist3, 16, normal, 0.8px, 22px);
    border: 0 transparent;
    outline: 0 transparent;
    background-color: transparent;
    width: 100%;

    &::placeholder {
      @include font-style($c-assist3, 16, normal, 0.8px, 22px);
    }
  }
}

.btn-wrap {
  margin-top: 50px;
  margin-bottom: 20px;
  @include flex(center);
}
</style>
