<template>
  <div class="c-avatar">
    <img class="avatar-img" src="@/assets/profile/img_profile.png" />

  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Avatar',
});
</script>
<style lang="scss" scoped>
.avatar-img {
  @include circle(35px);
  overflow: hidden;
}
</style>
