import { h } from 'vue';
import PlaylistsNameFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameFunction.vue';
import Avatar from '@/components/Global/MusicPlayer/Fragment/Avatar.vue';
import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';

import PlaylistsNameExpandFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameExpandFunction.vue';
import TrackDivideFunction from '@/components/Global/MusicPlayer/Fragment/TrackDivideFunction.vue';
import VersionAndEditNameFunction from '@/components/Global/MusicPlayer/Fragment/VersionAndEditNameFunction.vue';
import CountLabel from '@/components/Global/MusicPlayer/Fragment/CountLabel.vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
// import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
import MorePlaylists from '@/components/Global/MusicPlayer/Fragment/MorePlaylists.vue';
import MoreMusic from '@/components/Global/MusicPlayer/Fragment/MoreMusic.vue';

export const functionConfig = {
  search: {
    searchType: 'playlists', // 搜尋類別
    placeholder: '搜尋所有歌單',
  },
  info: {
    list: [
      {
        label: '總歌單',
        data: 12,
      },
      // {
      //   label: '來自其他用戶',
      //   data: 4,
      // },
    ],
  },
  listFilter: {
    checkboxs: [
      {
        label: 'Waves',
        value: 'waves',
      },
      {
        label: 'Description',
        value: 'description',
      },
    ],
  },
  functionIcons: {
    list: [
      {
        icon: 'move',
      },
      {
        icon: 'copy',
      },
      {
        icon: 'delete',
      },
      {
        icon: 'invite',
      },
      {
        icon: 'download',
      },
    ],
  },
  addPlaylistsBtn: {
    visible: true,
  },
};

export const mobileListConfig = {
  columns: [
    {
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      key: 'playlistsName',
      render: PlaylistsNameFunction,
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '60px',
      key: 'track',
      render: h(CountLabel, {
        label: 'Notes',
      }),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '24px',
      key: 'more',
      render: h(MorePlaylists, {
        functionList: ['share', 'edit-playlists', 'remove-playlists'],
      }),
    },
  ],
};

export const pcListConfig = {
  columns: [
    {
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      width: '300px',
      key: 'playlistsName',
      render: PlaylistsNameFunction,
    },
    {
      type: 'label',
      label: '',
      // width: '300px',
      align: 'left',
      key: 'owner',
      render: h(MusicFunction, {
        showMusicExpand: false,
        ml: true,
        functionList: ['add', 'download', 'share-playlists'],
      }),
    },

    {
      type: 'label',
      label: '擁有者',
      width: '60px',
      align: 'left',
      key: 'owner',
      render: Avatar,
    },
    {
      type: 'label',
      label: '曲目',
      width: '100px',
      align: 'left',
      key: 'track',
      render: Track,
    },
    {
      type: 'label',
      label: '修改日期',
      width: '100px',
      align: 'left',
      key: 'modifyDate',
      render: ModifyDate,
    },
    {
      type: 'label',
      label: 'REMOVE',
      width: '100px',
      align: 'center',
      key: 'remove',
      render: Remove,
    },
    {
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '60px',
      key: 'more',
      render: MorePlaylists,
    },
  ],
};

export const contentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
  },
];

export const mobileListDetailColumns = [
  {
    type: 'label',
    label: '123',
    align: 'center',
    // width: '24px',
    key: 'cover-name-code',
    render: CoverNameCodeNormal,
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'center',
    render: TimeLength,
  },
  {
    type: 'label',
    label: '',
    align: 'center',
    width: '24px',
    key: 'more',
    render: h(MoreMusic, {
      functionList: ['add-to-favorite', 'add-to-playlists', 'note', 'share', 'move-to-playlists', 'to-album-page'],
    }),
  },
];

export const pcListDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '300px',
    align: 'left',
    key: 'playlistsName',
    render: PlaylistsNameExpandFunction,
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showInnerMusicExpand: true,
    }),
  },
  {
    type: 'label',
    label: '版本',
    width: '60px',
    align: 'left',
    key: 'version',
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
    render: Remove,
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: '',
    align: 'center',
    width: '60px',
    key: 'more',
    render: MoreMusic,
  },
];

export const listDetailContentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '12:32',
    version: 5,
    trackNo: 5,
    id: 'MAT305-5',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,

    id: 'MAT305-76',
  },
];
export const musicDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '300px',
    align: 'left',
    key: 'playlistsName',
    render: VersionAndEditNameFunction,
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
  },
  {
    type: 'label',
    label: '版本',
    width: '60px',
    align: 'left',
    key: 'versdion',
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: 'none',
    width: '60px',
    align: 'left',

  },
];

export const musicDetailContentData = [
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛1',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

export const trackDivideColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '300px',
    align: 'left',
    key: 'playlistsName',
    render: TrackDivideFunction,
  },
  {
    type: 'label',
    label: '',

    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
    }),
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'empty',
  },
];

export const trackDivideData = [
  {
    name: 'Clarinet&Basso',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Low Pizzicato',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];
