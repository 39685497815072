<template>
  <div class="c-more">
    <img class="c-more__img" src="@/assets/myMusic/icon_more.svg" @click.stop="handleShowFunction(true)" />

    <template v-if="isSmPc">
      <n-collapse-transition :show="showFunction">
        <div class="more-list-wrap" v-click-away="() => handleShowFunction(false)">
          <ul class="more-list">
            <li
              class="more-list__item"
              v-for="item of list"
              :key="item.key"
              @click="handleClick(item.modalKey, item.tabKey)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </n-collapse-transition>
      <n-modal v-model:show="modelStatus['activity-share-record']" preset="dialog" :show-icon="false" :style="{'max-width': '800px'}">
        <model-base-layout title="開心的時候聽了更開心">
          <activity-share-record :activityTab="activityShareRecordTab" />
        </model-base-layout>
      </n-modal>

      <n-modal v-model:show="modelStatus['edit-playlist-name']" preset="dialog" :show-icon="false">
        <model-base-layout title="編輯歌單名稱" decoLine>
          <edit-playlist-name />
        </model-base-layout>
      </n-modal>
    </template>

    <template v-else>
      <mobile-function v-model:showFunction="showFunction" :functionList="functionList" />
    </template>

  </div>
</template>
<script>
import {
  defineComponent, ref, reactive, computed,
} from 'vue';
import { NCollapseTransition, NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import EditPlaylistName from '@/components/Global/Popup/EditPlaylistName.vue';
import ActivityShareRecord from '@/components/Global/Popup/ActivityShareRecord.vue';
import MobileFunction from '@/components/Global/MusicPlayer/MobileFunction.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'PlaylistsMore',
  props: {
    functionList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NCollapseTransition,
    NModal,
    ModelBaseLayout,
    EditPlaylistName,
    ActivityShareRecord,
    MobileFunction,
  },

  setup() {
    const siteStore = useSiteStore();
    const expandStatus = ref(false);
    const showFunction = ref(false);

    const list = [
      {
        key: 'view-activity-record',
        modalKey: 'activity-share-record',
        tabKey: 'activity',
        label: '查看活動紀錄',
      },
      {
        key: 'view-share-record',
        modalKey: 'activity-share-record',
        tabKey: 'share',
        label: '查看分享紀錄',
      },
      {
        key: 'edit-playlist-name',
        modalKey: 'edit-playlist-name',
        label: '編輯歌單名稱',
      },
    ];

    const isSmPc = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return true;
      }

      return false;
    });

    const modelStatus = reactive({
      'activity-share-record': false,
      'edit-playlist-name': false,
    });

    const activityShareRecordTab = ref('share');

    const handleShowFunction = (status) => {
      // toggleAlbumFunction(status);
      showFunction.value = status;
    };

    const handleClick = (key, tabKey) => {
      modelStatus[key] = true;

      if (key === 'activity-share-record') {
        activityShareRecordTab.value = tabKey;
      }

      handleShowFunction(false);
    };

    // const toggleAlbumFunction = (status) => {

    // };

    return {
      modelStatus,
      handleClick,
      list,
      expandStatus,

      handleShowFunction,
      activityShareRecordTab,
      showFunction,
      // toggleAlbumFunction,
      isSmPc,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-more {
  position: relative;

  &__img {
    cursor: pointer;
    width: 16px;
  }
}
.more-list-wrap {
  @include position(tr, 100%, 0);
  @include flex(center);
  @include padding(20px 0);
  width: 135px;
  background-color: $c-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000029;
  z-index: 10;
}

.more-list {
  &__item {
    @include font-style($c-assist4, 16, normal, 0.8px, 24px);
    cursor: pointer;

    & + & {
      margin-top: 12px;
    }
  }
}

@media screen and (min-width: $pc) {
  .more-list-wrap {
    @include position(tl, 100%, 0);
  }
}
</style>
